@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');
@import "assets/estilos/core/variables.scss";

html, body, #root, .App{
  // background-color: #eebf2f ;
    font-family: 'Roboto Condensed', sans-serif;
    width: 100%;
    height:100%;
    overflow: auto;
    // top:0 !important;
    background-color: #fff;
    overflow: auto;
  }

//   body{
//     padding-top: constant(safe-area-inset-top);
//     padding-top: env(safe-area-inset-top);
// }

a{
  text-decoration: none;
  color: inherit;
}

// .MuiIconButton-root {
//   padding: 15px !important;
// }

.MuiTypography-root{
    font-family: 'Roboto Condensed', sans-serif !important;
}

.MuiSelect-icon {
  color: $secondary-color !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: none;
  -webkit-text-fill-color: #1f3a4e;
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
  transition: #fff 5000s ease-in-out 0s;
}

.MuiInputBase-input {
    color: #1f3a4e !important;
}

.MuiAvatar-colorDefault {
  background-color: #eebf2f !important;
}

.viewSplash{
  width: 100vw;
  height: 100vh;
  background-color: #eebf2f !important;
} 

.img-splash{
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%)
}

.MuiCardMedia-media {
  width: 100%;
  border-bottom: 1px solid rgba(0,0,0,0.14);
}

.MuiButton-root {
  font-family: 'Roboto Condensed', sans-serif !important;
}

.bg-light-gray{
  background-color: $light-gray;
}

.w-100{
  width: 100% !important;
}

.h-100{
  height: 100% !important;
}

.ml-10{
  margin-left: 10px;
}

.p-2em{
  padding: 2em;
}

.all-green{
  background-color: $green !important;
  color: $green !important;
}

.iconSocials{
  color: $secondary-color !important;
}

.uppercase{
  text-transform: uppercase;
}

@media(min-width:769px){
.w-70margin{
  width: 70%;
  left:15%;
}
}

.img_banner{
  max-height: 250px;
  max-width: 100% !important;
}

.button-driver-acept{
  background-color: #d32f2f !important;
  color: #fff !important;
}

.button-driver-send{
  background-color: #388e3c!important;
  color: #fff !important;
}

.awssld__wrapper{
  height: auto !important;
  max-height: 250px !important;
  margin-bottom: 2% !important;
}

.awssld__content > img{
  max-width: 100%!important;
  height: auto !important;
  object-fit: contain !important;
}

.awssld__bullets button{
  width: 10px !important;
  height: 10px !important;
  background: $secondary-color !important;
}

.awssld__content{
  background-color: transparent !important;
}

.awssld__bullets {
  bottom: -25px !important;
}


@media(min-width: 768px) and (max-width: 1200px){
  .awssld__wrapper{
    max-height: 180px !important;
  }
}

@media(min-width: 1201px) and (max-width: 1600px){
  .awssld__wrapper{
    max-height: 200px !important;
  }
}

@media (max-width: 475px){
  .awssld__wrapper{
    max-height: 280px !important;
  }
}

@media (min-width: 476px) and (max-width: 769px){
  .awssld__wrapper{
    max-height: 100% !important;
    height: 100% !important;
  }
}

// .awssld__box{
//   height: auto !important;
//   max-height: 250px !important;
// }


.awssld{
  --loader-bar-color: $primary-color !important;
}