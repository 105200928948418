@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');

$primary-color:  #eebf2f;
$secondary-color:#1f3a4e;
$white: #fff;
$light-gray: #F9F9F9;
$medium-gray: #e9e9e9;
$green: #008000;

$primary-font:  'Bebas Neue', cursive !important;
$secondary-font:'Roboto Condensed', sans-serif !important ;