@import "assets/estilos/core/variables.scss";
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap");

// PRODUCTS LIST
.productTitle {
    font-family: $primary-font;
    color: $primary-color;
}

.productPrice {
    font-family: $primary-font;
    color: $primary-color;

    .oldPrice {
        font-size: 14px !important;
        margin-right: 5px;
        color: $secondary-color;
        text-decoration: line-through;
    }
}

.productDescription {
    font-family: $secondary-font;
    color: $secondary-color;
}

.bgSearch {
    background-color: $medium-gray;
    background-position: center;
    background-size: cover;
    width: 100%;
    min-height: 200px;

    // .bgOpacity {
    //     display: flex;
    //     flex-direction: row;
    //     justify-content: center;
    //     align-items: center;
    //     width: 100%;
    //     min-height: 300px;
    //     // background-color: rgba(255, 255, 255, 0.4);
    // }

    .title {
        text-transform: "uppercase";
        color: $secondary-color;
        font-size: 40px;
        font-family: "Bebas Neue", "cursive" !important;
    }

    .title2 {
        text-transform: "uppercase";
        color: $secondary-color;
        font-size: 40px;
        font-family: "Bebas Neue", "cursive" !important;
    }

    .input {
        background-color: $white !important;
    }
}

//PRODUCTS INSIDE

.gridProductsInside {
    text-align: center;
    img {
        max-width: 100%;
        max-height: 400px;
    }

    .textFieldProducts {
        border-bottom: none !important;
    }
}

.MuiInput-underline:before {
    border-bottom: none !important;
}

.MuiInput-underline:after {
    border-bottom: none !important;
}

.textfieldBusca {
    background-color: #fff;
    line-height: 1.75;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    min-height: 36px;
    padding-left: 5px !important;
}

.textfieldBuscaFiltro {
    min-height: 36px;
    border: 1px solid $secondary-color !important;
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
}

.borderButton {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
}

.gridFilters {
    border-right: 2px solid $primary-color;
}
